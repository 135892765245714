import React from 'react'
import { StyledLogosContainer, StyledLogoWrapper } from './styles'

import { StyledModuleContainer } from '@styles/global-components/_containers';

import WrImage from '@commons/wr-image'

export default function FeaturedLogos({
    module,
}) {
    if(module && module.icons) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'sm'}
            innerSpacing={true}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="featured-logos"
        >
            <StyledLogosContainer type={'outer'}>
                {module.icons.map(({ icon }, i) => (
                    <StyledLogoWrapper key={icon.imageFile.publicURL + i + 'featured-logo'}>
                         {/* <WrImage
                            imageFile={icon.imageFile}
                            alt={icon.altText}
                        /> */}
                        <img src={icon.imageFile.publicURL} alt={icon.altText}/>
                     </StyledLogoWrapper>
                ))}
            </StyledLogosContainer>
        </StyledModuleContainer>
    )
    return null;
}
