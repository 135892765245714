import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';

export const StyledFeaturedLogos = styled.section`
    background-color: ${props => props.backgroundColor || ''};
`

export const StyledLogosContainer = styled(StyledContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const StyledLogoWrapper = styled.div`
    padding-right: 5px;
    max-width: 25%;

    ${theme.mediaQuerys.mdUp} {
        max-width: 21%;
    }

    div {
        width: 100%;
    }

    &:first-of-type {
        max-width: 12.5%;

        ${theme.mediaQuerys.smUp} {
            max-width: 10%;
        }

        ${theme.mediaQuerys.mdUp} {
            max-width: 9%;
        }
    }

    &:last-of-type {
        padding-right: 0px;
    }
`
